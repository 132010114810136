var render = function () {
  var _vm$subtask, _vm$subtask$milestone, _vm$subtask$milestone2, _vm$subtask2, _vm$subtask3, _vm$subtask4, _vm$subtask5, _vm$subtask6, _vm$subtask7, _vm$subtask8, _vm$subtask9, _vm$subtask9$team, _vm$subtask10, _vm$subtask10$team, _vm$subtask11, _vm$subtask11$team, _vm$subtask12, _vm$subtask12$phase, _vm$subtask12$phase$d, _vm$subtask13, _vm$subtask13$phase, _vm$subtask13$phase$d, _vm$subtask14, _vm$subtask15, _vm$subtask16, _vm$subtask17, _vm$subtask18, _vm$subtask19, _vm$subtask20, _vm$subtask21, _vm$subtask22, _vm$subtask23, _vm$subtask24, _vm$subtask25, _vm$subtask26, _vm$subtask27, _vm$subtask28, _vm$subtask29, _vm$subtask30, _vm$subtask31, _vm$subtask32, _vm$subtask33, _vm$subtask34, _vm$subtask35, _vm$subtask36, _vm$subtask37, _vm$subtask38;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [[_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8",
      "xs": "12"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Board Name',
      expression: "'Board Name'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mb-2",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$subtask = _vm.subtask) === null || _vm$subtask === void 0 ? void 0 : (_vm$subtask$milestone = _vm$subtask.milestoneTaskBoard) === null || _vm$subtask$milestone === void 0 ? void 0 : (_vm$subtask$milestone2 = _vm$subtask$milestone.data) === null || _vm$subtask$milestone2 === void 0 ? void 0 : _vm$subtask$milestone2.title) + " ")]), (_vm$subtask2 = _vm.subtask) !== null && _vm$subtask2 !== void 0 && _vm$subtask2.title ? [_c('div', [_c('b-card-text', [_c('h2', [+((_vm$subtask3 = _vm.subtask) === null || _vm$subtask3 === void 0 ? void 0 : _vm$subtask3.sequence_num) ? [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(_vm._s((_vm$subtask4 = _vm.subtask) === null || _vm$subtask4 === void 0 ? void 0 : _vm$subtask4.sequence_num))])] : _vm._e(), _vm._v(" " + _vm._s((_vm$subtask5 = _vm.subtask) === null || _vm$subtask5 === void 0 ? void 0 : _vm$subtask5.title) + " "), _c('feather-icon', {
    attrs: {
      "icon": "CopyIcon"
    },
    on: {
      "click": _vm.copySubTaskUrlToClipboard
    }
  })], 2), _vm.showCopiedMessage ? _c('span', {
    staticClass: "copied-message"
  }, [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Link Copied! ")])], 1) : _vm._e()])], 1)] : _vm._e()], 2), _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_EDIT, _vm.permissions) ? [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [(_vm$subtask6 = _vm.subtask) !== null && _vm$subtask6 !== void 0 && _vm$subtask6.title ? [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.showSubTaskModal(_vm.subtask);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "EditIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Edit")])], 1)], 1)] : _vm._e()], 2)] : _vm._e()], 2), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-button-group', {
    staticClass: "button-padding"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showSubTaskLinkForm
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "LinkIcon"
    }
  }), _vm._v(" Link Sub Task ")], 1)], 1)], 1), _vm.isSubTaskLinkFormLoaded ? [_c('b-col', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('validation-observer', {
    ref: "subTaskLinkAdd"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.subTaskLinkAddForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sub_task_title",
      "vid": "sub_task_list_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "sub_task_title",
            "placeholder": "Search Sub task and link here",
            "options": _vm.subtasksLinkOption,
            "label": "name"
          },
          model: {
            value: _vm.sub_task_list_id,
            callback: function callback($$v) {
              _vm.sub_task_list_id = $$v;
            },
            expression: "sub_task_list_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4121963227)
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_vm.isSubTaskLinkLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeSubTaskLinkForm
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-1 btn-sm",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.subtaskLinkList.length > 0 ? "Linked SubTask List:" : "") + " ")]), _c('b-list-group', _vm._l(_vm.subtaskLinkList, function (subtaskList) {
    return _c('b-list-group-item', {
      key: subtaskList === null || subtaskList === void 0 ? void 0 : subtaskList.id,
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowSubTask(subtaskList === null || subtaskList === void 0 ? void 0 : subtaskList.task_subtask_id);
        }
      }
    }, [_vm._v(_vm._s(subtaskList === null || subtaskList === void 0 ? void 0 : subtaskList.task_subtask_title))]), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteLinkSubTask(subtaskList === null || subtaskList === void 0 ? void 0 : subtaskList.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 2), (_vm$subtask7 = _vm.subtask) !== null && _vm$subtask7 !== void 0 && _vm$subtask7.description ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [[_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Description:")]), _c('b-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$subtask8 = _vm.subtask) === null || _vm$subtask8 === void 0 ? void 0 : _vm$subtask8.description)
    }
  })])]], 2)])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Comments"
    }
  }, [_c('div', {
    staticClass: "comment-scrollable",
    attrs: {
      "active": ""
    }
  }, [_c('b-card-text', [_c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "12",
      "xl": "12"
    }
  }, [_vm.isShowCommentModal == false ? [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-form-input', {
    staticClass: "custom-font",
    attrs: {
      "id": "type",
      "type": "text",
      "placeholder": "Add Comment....",
      "autocomplete": "off"
    },
    on: {
      "click": _vm.showCommentModal
    }
  })], 1)] : _vm._e(), _vm.taskEditClicked == false && _vm.isShowCommentModal == true ? [_c('div', {
    staticClass: "mt-1 mx-2"
  }, [_c('validation-observer', {
    ref: "addCommentFormValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addCommentForm($event);
      }
    }
  }, [_c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('div', {
          class: {
            fullscreen: _vm.isFullscreen
          }
        }, [_c('quill-editor', {
          staticClass: "custom-quill-editor",
          style: {
            width: '98% !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.commentDescription,
            callback: function callback($$v) {
              _vm.commentDescription = $$v;
            },
            expression: "commentDescription"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v(" Italic ")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v(" Underline ")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v(" Strike ")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        }), _c('button', {
          staticClass: "fullscreen-btn",
          attrs: {
            "type": "button",
            "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
          },
          on: {
            "click": _vm.toggleFullscreen
          }
        }, [_vm._v(" 🔳 ")])])])])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2047871387)
  })], 1), _vm.isLoading ? [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Post Comment ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.hiddenCommentModal
    }
  }, [_vm._v(" Cancel ")])]], 2)], 1)], 1)] : _vm._e(), _vm._l(_vm.commentList, function (comment, index) {
    var _comment$createdBy, _comment$createdBy$da, _comment$createdBy2, _comment$createdBy2$d, _comment$createdBy3, _comment$createdBy3$d, _vm$authUser, _comment$createdBy4, _comment$createdBy4$d, _vm$authUser2, _comment$createdBy5, _comment$createdBy5$d;
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-start mx-2 my-1"
    }, [_c('b-avatar', {
      staticClass: "mt-25 mr-75",
      attrs: {
        "src": (comment === null || comment === void 0 ? void 0 : (_comment$createdBy = comment.createdBy) === null || _comment$createdBy === void 0 ? void 0 : (_comment$createdBy$da = _comment$createdBy.data) === null || _comment$createdBy$da === void 0 ? void 0 : _comment$createdBy$da.avatar) === '' ? '/avatar.svg' : comment === null || comment === void 0 ? void 0 : (_comment$createdBy2 = comment.createdBy) === null || _comment$createdBy2 === void 0 ? void 0 : (_comment$createdBy2$d = _comment$createdBy2.data) === null || _comment$createdBy2$d === void 0 ? void 0 : _comment$createdBy2$d.avatar,
        "size": "34"
      }
    }), _c('div', {
      staticClass: "profile-user-info w-100"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(comment === null || comment === void 0 ? void 0 : (_comment$createdBy3 = comment.createdBy) === null || _comment$createdBy3 === void 0 ? void 0 : (_comment$createdBy3$d = _comment$createdBy3.data) === null || _comment$createdBy3$d === void 0 ? void 0 : _comment$createdBy3$d.name) + " ")])]), _c('div', [_vm.isShowEditCommentModal == true && _vm.editCommentModalIndex == index ? [_c('div', {
      staticClass: "mt-1"
    }, [_c('validation-observer', {
      ref: "editCommentFormValidation",
      refInFor: true
    }, [_c('b-form', {
      on: {
        "submit": function submit($event) {
          $event.preventDefault();
          return _vm.editCommentForm($event);
        }
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "description",
        "vid": "description",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('div', {
            class: {
              fullscreen: _vm.isFullscreen
            }
          }, [_c('quill-editor', {
            staticClass: "custom-quill-editor",
            style: {
              width: '98% !important'
            },
            attrs: {
              "options": _vm.editorOption
            },
            model: {
              value: _vm.commentDescription,
              callback: function callback($$v) {
                _vm.commentDescription = $$v;
              },
              expression: "commentDescription"
            }
          }, [_c('div', {
            attrs: {
              "slot": "toolbar",
              "id": "toolbar"
            },
            slot: "toolbar"
          }, [_c('span', {
            staticClass: "ql-formats"
          }, [_c('button', {
            staticClass: "ql-bold"
          }, [_vm._v(" Bold ")]), _c('button', {
            staticClass: "ql-italic"
          }, [_vm._v(" Italic ")]), _c('button', {
            staticClass: "ql-underline"
          }, [_vm._v(" Underline ")]), _c('button', {
            staticClass: "ql-strike"
          }, [_vm._v(" Strike ")]), _c('button', {
            staticClass: "ql-blockquote"
          }), _c('button', {
            staticClass: "ql-list",
            attrs: {
              "value": "ordered"
            }
          }), _c('button', {
            staticClass: "ql-list",
            attrs: {
              "value": "bullet"
            }
          }), _c('button', {
            staticClass: "ql-script",
            attrs: {
              "value": "sub"
            }
          }), _c('button', {
            staticClass: "ql-script",
            attrs: {
              "value": "super"
            }
          }), _c('button', {
            staticClass: "ql-indent",
            attrs: {
              "value": "-1"
            }
          }), _c('button', {
            staticClass: "ql-indent",
            attrs: {
              "value": "+1"
            }
          }), _c('button', {
            staticClass: "ql-direction",
            attrs: {
              "value": "rtl"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": ""
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "center"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "right"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "justify"
            }
          }), _c('select', {
            staticClass: "ql-color"
          }), _c('select', {
            staticClass: "ql-background"
          }), _c('select', {
            staticClass: "ql-size"
          }, [_c('option', {
            attrs: {
              "value": "small"
            }
          }), _c('option', {
            attrs: {
              "selected": ""
            }
          }), _c('option', {
            attrs: {
              "value": "large"
            }
          }), _c('option', {
            attrs: {
              "value": "huge"
            }
          })]), _c('select', {
            staticClass: "ql-font"
          }), _c('select', {
            staticClass: "ql-header"
          }, [_c('option', {
            attrs: {
              "value": "1"
            }
          }, [_vm._v(" Heading 1 ")]), _c('option', {
            attrs: {
              "value": "2"
            }
          }, [_vm._v(" Heading 2 ")]), _c('option', {
            attrs: {
              "value": "3"
            }
          }, [_vm._v(" Heading 3 ")]), _c('option', {
            attrs: {
              "value": "4"
            }
          }, [_vm._v(" Heading 4 ")]), _c('option', {
            attrs: {
              "value": "5"
            }
          }, [_vm._v(" Heading 5 ")]), _c('option', {
            attrs: {
              "value": "6"
            }
          }, [_vm._v(" Heading 6 ")]), _c('option', {
            attrs: {
              "selected": ""
            }
          }, [_vm._v(" Normal ")])]), _c('button', {
            staticClass: "ql-link"
          }), _c('button', {
            staticClass: "fullscreen-btn",
            attrs: {
              "type": "button",
              "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
            },
            on: {
              "click": _vm.toggleFullscreen
            }
          }, [_vm._v(" 🔳 ")])])])])], 1), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1), _vm.isLoading ? [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary",
        "disabled": ""
      }
    }, [_c('b-spinner', {
      attrs: {
        "small": ""
      }
    }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mr-1",
      attrs: {
        "size": "sm",
        "variant": "primary",
        "type": "submit"
      }
    }, [_vm._v(" Submit ")]), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": _vm.hiddenEditCommentModal
      }
    }, [_vm._v(" Cancel ")])]], 2)], 1)], 1)] : [_c('small', {
      domProps: {
        "innerHTML": _vm._s(comment === null || comment === void 0 ? void 0 : comment.description)
      }
    }), _c('small', [_c('span', {
      staticClass: "mr-1"
    }, [((_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : _vm$authUser.id) == (comment === null || comment === void 0 ? void 0 : (_comment$createdBy4 = comment.createdBy) === null || _comment$createdBy4 === void 0 ? void 0 : (_comment$createdBy4$d = _comment$createdBy4.data) === null || _comment$createdBy4$d === void 0 ? void 0 : _comment$createdBy4$d.id) ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowEditComment(index, comment);
        }
      }
    }, [_vm._v("Edit")]) : _vm._e()], 1), _c('span', {
      staticClass: "mr-1"
    }, [((_vm$authUser2 = _vm.authUser) === null || _vm$authUser2 === void 0 ? void 0 : _vm$authUser2.id) == (comment === null || comment === void 0 ? void 0 : (_comment$createdBy5 = comment.createdBy) === null || _comment$createdBy5 === void 0 ? void 0 : (_comment$createdBy5$d = _comment$createdBy5.data) === null || _comment$createdBy5$d === void 0 ? void 0 : _comment$createdBy5$d.id) ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onCommentDelete(comment === null || comment === void 0 ? void 0 : comment.id);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e()], 1)])]], 2)])], 1);
  })], 2)], 1)], 1)], 1)])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4",
      "xs": "12"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Assign User")]), _c('div', [_vm._l((_vm$subtask9 = _vm.subtask) === null || _vm$subtask9 === void 0 ? void 0 : (_vm$subtask9$team = _vm$subtask9.team) === null || _vm$subtask9$team === void 0 ? void 0 : _vm$subtask9$team.data, function (member, index) {
    return _c('span', {
      key: index
    }, [index <= 2 ? [_c('b-avatar', {
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    })] : _vm._e()], 2);
  }), ((_vm$subtask10 = _vm.subtask) === null || _vm$subtask10 === void 0 ? void 0 : (_vm$subtask10$team = _vm$subtask10.team) === null || _vm$subtask10$team === void 0 ? void 0 : _vm$subtask10$team.data.length) > 3 ? _c('span', [_vm._v(" + " + _vm._s(((_vm$subtask11 = _vm.subtask) === null || _vm$subtask11 === void 0 ? void 0 : (_vm$subtask11$team = _vm$subtask11.team) === null || _vm$subtask11$team === void 0 ? void 0 : _vm$subtask11$team.data.length) - 3) + " more ")]) : _vm._e(), _c('b-button', {
    staticClass: "btn-icon rounded-circle btn-sm ml-1",
    attrs: {
      "variant": "outline-primary",
      "disabled": _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_EDIT, _vm.permissions) ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.showAddMemberModal(_vm.subtask);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserPlusIcon"
    }
  })], 1)], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Phase")]), (_vm$subtask12 = _vm.subtask) !== null && _vm$subtask12 !== void 0 && (_vm$subtask12$phase = _vm$subtask12.phase) !== null && _vm$subtask12$phase !== void 0 && (_vm$subtask12$phase$d = _vm$subtask12$phase.data) !== null && _vm$subtask12$phase$d !== void 0 && _vm$subtask12$phase$d.title ? [_c('b-dropdown', {
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "no-caret": "",
      "variant": "outline-primary",
      "text": (_vm$subtask13 = _vm.subtask) === null || _vm$subtask13 === void 0 ? void 0 : (_vm$subtask13$phase = _vm$subtask13.phase) === null || _vm$subtask13$phase === void 0 ? void 0 : (_vm$subtask13$phase$d = _vm$subtask13$phase.data) === null || _vm$subtask13$phase$d === void 0 ? void 0 : _vm$subtask13$phase$d.title,
      "size": "sm"
    }
  }, [_vm._l(_vm.subtaskPhaseOptions, function (phase) {
    return _c('b-dropdown-item', {
      key: phase === null || phase === void 0 ? void 0 : phase.id,
      on: {
        "click": function click($event) {
          return _vm.updatePhase(phase);
        }
      }
    }, [_vm._v(" " + _vm._s(phase === null || phase === void 0 ? void 0 : phase.title) + " ")]);
  }), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    on: {
      "click": _vm.onShowPhase
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusCircleIcon"
    }
  }), _vm._v(" Add More ")], 1)], 2)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Type")]), _c('b-card-text', [(_vm$subtask14 = _vm.subtask) !== null && _vm$subtask14 !== void 0 && _vm$subtask14.type ? [((_vm$subtask15 = _vm.subtask) === null || _vm$subtask15 === void 0 ? void 0 : _vm$subtask15.type) == 'Task' || ((_vm$subtask16 = _vm.subtask) === null || _vm$subtask16 === void 0 ? void 0 : _vm$subtask16.type) == 'Subtask' || ((_vm$subtask17 = _vm.subtask) === null || _vm$subtask17 === void 0 ? void 0 : _vm$subtask17.type) == 'General' ? [_c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s((_vm$subtask18 = _vm.subtask) === null || _vm$subtask18 === void 0 ? void 0 : _vm$subtask18.type) + " ")])] : ((_vm$subtask19 = _vm.subtask) === null || _vm$subtask19 === void 0 ? void 0 : _vm$subtask19.type) == 'Bug' || ((_vm$subtask20 = _vm.subtask) === null || _vm$subtask20 === void 0 ? void 0 : _vm$subtask20.type) == 'Issue' ? [_c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" " + _vm._s((_vm$subtask21 = _vm.subtask) === null || _vm$subtask21 === void 0 ? void 0 : _vm$subtask21.type) + " ")])] : _vm._e()] : _vm._e(), [((_vm$subtask22 = _vm.subtask) === null || _vm$subtask22 === void 0 ? void 0 : _vm$subtask22.sequence_num) > 0 ? [_c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" Sequential ")])] : [_c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Non Sequential ")])]]], 2)], 1)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Priority")]), ((_vm$subtask23 = _vm.subtask) === null || _vm$subtask23 === void 0 ? void 0 : _vm$subtask23.priority) === 'high' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" High ")])], 1)] : ((_vm$subtask24 = _vm.subtask) === null || _vm$subtask24 === void 0 ? void 0 : _vm$subtask24.priority) === 'low' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Low ")])], 1)] : ((_vm$subtask25 = _vm.subtask) === null || _vm$subtask25 === void 0 ? void 0 : _vm$subtask25.priority) === 'medium' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(" Medium ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Normal ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Status")]), (_vm$subtask26 = _vm.subtask) !== null && _vm$subtask26 !== void 0 && _vm$subtask26.is_completed ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Completed ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Not Completed ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Estimation Point")]), (_vm$subtask27 = _vm.subtask) !== null && _vm$subtask27 !== void 0 && _vm$subtask27.point ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$subtask28 = _vm.subtask) === null || _vm$subtask28 === void 0 ? void 0 : _vm$subtask28.point) + " ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Deadline")]), (_vm$subtask29 = _vm.subtask) !== null && _vm$subtask29 !== void 0 && _vm$subtask29.deadline ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$subtask30 = _vm.subtask) === null || _vm$subtask30 === void 0 ? void 0 : _vm$subtask30.deadline)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Actual Start Date")]), (_vm$subtask31 = _vm.subtask) !== null && _vm$subtask31 !== void 0 && _vm$subtask31.actual_start_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$subtask32 = _vm.subtask) === null || _vm$subtask32 === void 0 ? void 0 : _vm$subtask32.actual_start_date)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Actual End Date")]), (_vm$subtask33 = _vm.subtask) !== null && _vm$subtask33 !== void 0 && _vm$subtask33.actual_end_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$subtask34 = _vm.subtask) === null || _vm$subtask34 === void 0 ? void 0 : _vm$subtask34.actual_end_date)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), (_vm$subtask35 = _vm.subtask) !== null && _vm$subtask35 !== void 0 && _vm$subtask35.wordcount ? _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Word Count")]), [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$subtask36 = _vm.subtask) === null || _vm$subtask36 === void 0 ? void 0 : _vm$subtask36.wordcount) + " ")])], 1)]], 2)]) : _vm._e(), (_vm$subtask37 = _vm.subtask) !== null && _vm$subtask37 !== void 0 && _vm$subtask37.contentlink ? _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Content Link")]), (_vm$subtask38 = _vm.subtask) !== null && _vm$subtask38 !== void 0 && _vm$subtask38.contentlink ? [_c('b-card-text', [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "primary"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.subtask.contentlink,
      "target": "_blank"
    }
  }, [_vm._v("View Content")])])], 1)] : _vm._e()], 2)]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-code', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Activity Log"
    }
  }, [_c('div', {
    staticClass: "log-scrollable"
  }, [_c('b-card', [_c('app-timeline', _vm._l(_vm.subTaskActivityLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": _vm.setLogVariant(log)
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log))
      }
    })]);
  }), 1)], 1)], 1)]), _c('b-tab', {
    attrs: {
      "title": "Phase Duration Log"
    },
    on: {
      "click": _vm.loadTimeDurationLog
    }
  }, [_c('div', {
    staticClass: "log-scrollable"
  }, [_c('b-card', [_vm.phaseChangeLog.length > 0 ? [_c('app-timeline', _vm._l(_vm.phaseChangeLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": _vm.setLogVariant(log)
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatPhaseLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatPhaseLogDescription(log === null || log === void 0 ? void 0 : log.created_at, _vm.nextPhaseChangeStartTime[index]))
      }
    })]);
  }), 1)] : _vm._e()], 2)], 1)])], 1)], 1)], 1)], 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-sub-task-board-phase-task",
      "centered": "",
      "title": "Edit Sub-Task Information",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenSubTaskModal
    }
  }, [_c('validation-observer', {
    ref: "subtaskEdit"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.subtaskEditForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter sub task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type ",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "type",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "type",
            "options": _vm.taskSubtaskTypeOptionConstant,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select task type",
            "label": "name"
          },
          model: {
            value: _vm.type,
            callback: function callback($$v) {
              _vm.type = $$v;
            },
            expression: "type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority",
      "label-for": "priority"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "priority",
            "options": _vm.priorityOptionConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select sub task priority",
            "label": "name"
          },
          model: {
            value: _vm.selectPriorityType,
            callback: function callback($$v) {
              _vm.selectPriorityType = $$v;
            },
            expression: "selectPriorityType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_vm.taskEditClicked ? [_c('div', {
          class: {
            fullscreen: _vm.isFullscreen
          }
        }, [_c('quill-editor', {
          staticClass: "custom-quill-editor",
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v("Italic")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v("Underline")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v("Strike")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        }), _c('button', {
          staticClass: "fullscreen-btn",
          attrs: {
            "type": "button",
            "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
          },
          on: {
            "click": _vm.toggleFullscreen
          }
        }, [_vm._v(" 🔳 ")])])])])], 1)] : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.previousFiles.length > 0 ? [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.previousFiles, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: item.file_name,
        expression: "item.file_name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isPreviousFileImage(item) ? [_c('img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.original_url,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePreviousFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1)] : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "file-input"
  }, [_c('label', {
    staticClass: "attach-icon",
    attrs: {
      "for": "file-upload"
    }
  }, [_vm._v(" Attach File "), _c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "#7367f0"
    },
    attrs: {
      "icon": "PaperclipIcon"
    }
  })], 1), _c('input', {
    attrs: {
      "hidden": "",
      "id": "file-upload",
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.handleFileUpload
    }
  })])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.uploadedFiles, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: file.name,
        expression: "file.name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isImage(file) ? [_c('img', {
      attrs: {
        "src": file.preview,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimation Point",
      "label-for": "point"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "point",
      "vid": "point",
      "rules": "min_value:0|max_value:9999"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "point",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Estimation Point"
          },
          model: {
            value: _vm.point,
            callback: function callback($$v) {
              _vm.point = $$v;
            },
            expression: "point"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deadline",
      "label-for": "deadline"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "deadline",
      "vid": "deadline"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "deadline",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Deadline"
          },
          model: {
            value: _vm.deadline,
            callback: function callback($$v) {
              _vm.deadline = $$v;
            },
            expression: "deadline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Start Date",
      "label-for": "actual_start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_start_date",
      "vid": "actual_start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual Start Date "
          },
          model: {
            value: _vm.actualStartDate,
            callback: function callback($$v) {
              _vm.actualStartDate = $$v;
            },
            expression: "actualStartDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual End Date",
      "label-for": "actual_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_end_date",
      "vid": "actual_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual End Date "
          },
          model: {
            value: _vm.actualEndDate,
            callback: function callback($$v) {
              _vm.actualEndDate = $$v;
            },
            expression: "actualEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Word Count",
      "label-for": "wordcount"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "wordcount",
      "vid": "wordcount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "wordcount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter sub task Content Wordcount",
            "disabled": _vm.TaskWordcount > 0
          },
          model: {
            value: _vm.wordcount,
            callback: function callback($$v) {
              _vm.wordcount = $$v;
            },
            expression: "wordcount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.TaskWordcount > 0 ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v("Subtask word count not available when task has word count")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Content Link",
      "label-for": "contentlink"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "contentlink",
      "vid": "contentlink"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "contentlink",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter sub task Content Content Link"
          },
          model: {
            value: _vm.contentlink,
            callback: function callback($$v) {
              _vm.contentlink = $$v;
            },
            expression: "contentlink"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-add-member-form",
      "centered": "",
      "title": "Add Member",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "filterable": true,
            "options": _vm.allMemberIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }